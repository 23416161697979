<template>
  <div id="events">
    <b-container class="mt-3">
      <h5>Nutzer erstellen</h5>
      <b-col>
        <b-form @submit.prevent="onSubmit">
          <b-form-group
            id="input-group-1"
            label="E-Mail-Adresse:"
            label-for="input-1"
            description="Der Nutzer muss sich mit dieser E-Mail-Adresse anmelden."
          >
            <b-form-input
              id="input-1"
              v-model="form.email"
              type="email"
              placeholder="E-Mail-Adresse"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Name" label-for="input-2">
            <b-form-input
              id="input-2"
              v-model="form.name"
              placeholder="Name"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" label="Firma" label-for="input-3">
            <b-form-input
              id="input-3"
              v-model="form.company"
              placeholder="Firma"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Gruppe" label-for="input-4">
            <b-form-select id="input-4" v-model="form.group" :options="options" :select-size="3"></b-form-select>
          </b-form-group>

          <b-button type="submit" variant="primary">Erstellen</b-button>
        </b-form>
      </b-col>
    </b-container>
    <b-container class="mb-5 mt-3">
      <b-row>
        <b-col>
          <b-alert show v-show="success" variant="success">{{success}}</b-alert>
          <b-alert show v-show="error" variant="danger">{{error}}</b-alert>
        </b-col>
      </b-row>
    </b-container>
    <ImportUsers/>
    <div v-if="users">
      <ShowUsers :registered="users"/>
    </div>
  </div>
</template>

<script>
import { firestore, functions } from "../firebase"
import { setDoc, doc } from "firebase/firestore"
import { httpsCallable } from "firebase/functions"
import ShowUsers from "./ShowUsers"
import ImportUsers from "./ImportUsers"

export default {
  name: 'Users',
  components: {
    ShowUsers,
    ImportUsers
  },
  data(){
    return {
      users: [],
      form:{},
      success: false,
      error: false,
      options: [
          { value: 'Mittwoch', text: 'Mittwoch' },
          { value: 'Donnerstag', text: 'Donnerstag' },
          { value: 'Hersteller', text: 'Hersteller' },
          { value: 'ComLine', text: 'ComLine' }
      ]
    }
  },
  methods:{
    onSubmit(){
      let form = this.form
      this.form.email = this.form.email.toLowerCase()
      if (form){
        setDoc(doc(firestore, 'users',  form.email), form)
        .then(()=>{
          this.$store.dispatch('fetchUsers')
          this.success = "Die Nutzer wurde erstellt."
          this.form = {}
        })
        .catch((error) => {
          this.error = error
        });
      } else {
        this.error = 'Bitte fülle alle Felder aus.'
      }
    },
    getUserData(){
      const getUsers = httpsCallable(functions, 'getUsers');
      getUsers()
      .then((users)=>{
        this.users = users.data.users
      })
      .catch(err=>{console.log(err)})
    },
  },
  mounted(){
    this.getUserData()
 }
}
</script>

<style scoped>
 
</style>
