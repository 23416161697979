<template>
  <div class="wrapper">
    <b-container>
      <h4>Push-Nachrichten verschicken</h4>
      <b-form @submit.prevent="handleSubmit()">
        <b-form-group label="Titel" label-for="push-message-title">
          <b-input
            id="push-message-title"
            v-model="form.title"
            placeholder="Titel hier eingeben..."
            required
          />
        </b-form-group>
        <b-form-group label="Nachricht:" label-for="push-message-body">
          <b-form-textarea
            id="push-message-body"
            v-model="form.message"
            placeholder="Nachricht hier eingeben..."
            rows="3"
            max-rows="6"
            style="overflow-y: auto"
            required
          />
        </b-form-group>
        <b-button
          type="submit"
          variant="primary"
          :disabled="!form.title || !form.message"
          >Abschicken</b-button
        >
      </b-form>
    </b-container>
    <b-modal id="push-message-dialog" hide-footer>
      <template #modal-title> Push-Nachricht wirklich versenden? </template>
      <div class="d-block text-center">
        <p>Soll die Nachricht</p>
        <div class="message">
            <h5>{{ form.title }}</h5>
            <p>{{ form.message }}</p>
        </div>
        <p>wirklich versendet werden?</p>
      </div>
      <b-button
        class="mt-3"
        variant="success"
        block
        @click="sendMessage()"
        >Bestätigen</b-button
      >
      <b-button
        class="mt-3"
        variant="danger"
        block
        @click="$bvModal.hide('push-message-dialog')"
        >Abbrechen</b-button
      >
    </b-modal>
  </div>
</template>

<script>
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  name: "SendMessages",
  components: {},
  data() {
    return {
      form: {
        title: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      this.$bvModal.show("push-message-dialog");
    },
    sendMessage() {
      const sendPushMessage = httpsCallable(functions, "sendPushMessage");
      sendPushMessage(this.form)
      this.$bvModal.hide("push-message-dialog")
      this.form.message = ""
      this.form.title = ""
    },
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.message {
  font-weight: 200;
  font-style: italic;
  padding: 32px;
}
</style>
