<template>
  <div id="events">
    <b-container class="mt-3 mb-3">
      <b-row>
        <b-col>
          <div class="sticky-top">
            <h4>Teilnehmer</h4>
            <b-list-group>
              <b-list-group-item
                class="clickable"
                v-for="(user, index) in users"
                :key="index"
                @click="selectedUser = index"
                :class="selectedUser === index && 'selected'"
              >
                <div>
                  {{ user.name }}<br />{{ user.company }}<br />{{ user.email }}
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
        <b-col class="sticky-top ownScroll">
          <div>
            <h4>Termine</h4>
            <b-list-group v-if="meetings">
              <b-list-group-item
                class="clickable"
                v-for="(time, index) in times"
                :key="time.name + index"
                :class="
                  (isInTime(time.name, users[selectedUser]).length == 1 &&
                    'selected') ||
                  (isInTime(time.name, users[selectedUser]).length > 1 &&
                    'selectedRed')
                "
                >{{ time.name }}<br />
                <span
                  v-for="(room, index) in isInTime(
                    time.name,
                    users[selectedUser]
                  )"
                  :key="index"
                  >{{ room.room }} - {{ room.titel }}<br
                /></span>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MeetingCheck",
  computed: {
    ...mapGetters({
      users: "users",
      meetings: "meetings",
    }),
  },
  data() {
    return {
      selectedUser: 0,
      times: [
        // Mittwoch:
        {
          showtime: "10:00 - 12:00",
          name: "Mi. 10:00 - 12:00",
          time: new Date(Date.UTC(2025, 2, 26, 9, 0, 0)),
        },
        {
          showtime: "10:30 - 11:30",
          name: "Mi. 10:30 - 11:30",
          time: new Date(Date.UTC(2025, 2, 26, 9, 30, 0)),
        },
        {
          showtime: "12:00 - 12:30",
          name: "Mi. 12:00 - 12:30",
          time: new Date(Date.UTC(2025, 2, 26, 11, 0, 0)),
        },
        {
          showtime: "12:30 - 15:00",
          name: "Mi. 12:30 - 15:00",
          time: new Date(Date.UTC(2025, 2, 26, 11, 30, 0)),
        },
        {
          showtime: "14:00 - 14:30",
          name: "Mi. 14:00 - 14:30",
          time: new Date(Date.UTC(2025, 2, 26, 13, 0, 0)),
        },
        {
          showtime: "14:00 - 16:00",
          name: "Mi. 14:00 - 16:00",
          time: new Date(Date.UTC(2025, 2, 26, 13, 0, 0)),
        },
        {
          showtime: "15:00 - 16:00",
          name: "Mi. 15:00 - 16:00",
          time: new Date(Date.UTC(2025, 2, 26, 14, 0, 0)),
        },
        {
          showtime: "16:00 - 16:30",
          name: "Mi. 16:00 - 16:30",
          time: new Date(Date.UTC(2025, 2, 26, 15, 0, 0)),
        },
        {
          showtime: "16:30 - 17:00",
          name: "Mi. 16:30 - 17:00",
          time: new Date(Date.UTC(2025, 2, 26, 15, 30, 0)),
        },
        {
          showtime: "17:00 - 17:30",
          name: "Mi. 17:00 - 17:30",
          time: new Date(Date.UTC(2025, 2, 26, 16, 0, 0)),
        },
        {
          showtime: "17:30 - 18:00",
          name: "Mi. 17:30 - 18:00",
          time: new Date(Date.UTC(2025, 2, 26, 16, 30, 0)),
        },
        {
          showtime: "19:00 - 21:00",
          name: "Mi. 19:00 - 21:00",
          time: new Date(Date.UTC(2025, 2, 26, 18, 0, 0)),
        },
        {
          showtime: "21:00 - 23:00",
          name: "Mi. 21:00 - 23:00",
          time: new Date(Date.UTC(2025, 2, 26, 20, 0, 0)),
        },
        // Donnerstag:
        {
          showtime: "07:00 - 09:30",
          name: "Do. 07:00 - 09:30",
          time: new Date(Date.UTC(2025, 2, 27, 6, 0, 0)),
        },
        {
          showtime: "08:00 - 10:00",
          name: "Do. 08:00 - 10:00",
          time: new Date(Date.UTC(2025, 2, 27, 7, 0, 0)),
        },
        {
          showtime: "09:00 - 12:00",
          name: "Do. 09:00 - 12:00",
          time: new Date(Date.UTC(2025, 2, 27, 8, 0, 0)),
        },
        {
          showtime: "09:30 - 10:00",
          name: "Do. 09:30 - 10:00",
          time: new Date(Date.UTC(2025, 2, 27, 8, 30, 0)),
        },
        {
          showtime: "10:00 - 10:30",
          name: "Do. 10:00 - 10:30",
          time: new Date(Date.UTC(2025, 2, 27, 9, 0, 0)),
        },
        {
          showtime: "10:30 - 11:00",
          name: "Do. 10:30 - 11:00",
          time: new Date(Date.UTC(2025, 2, 27, 9, 30, 0)),
        },
        {
          showtime: "11:00 - 11:30",
          name: "Do. 11:00 - 11:30",
          time: new Date(Date.UTC(2025, 2, 27, 10, 0, 0)),
        },
        {
          showtime: "11:30 - 12:30",
          name: "Do. 11:30 - 12:30",
          time: new Date(Date.UTC(2025, 2, 27, 10, 30, 0)),
        },
        {
          showtime: "12:30 - 12:45",
          name: "Do. 12:30 - 12:45",
          time: new Date(Date.UTC(2025, 2, 27, 11, 30, 0)),
        },    
        {
          showtime: "12:45 - 13:15",
          name: "Do. 12:45 - 13:15",
          time: new Date(Date.UTC(2025, 2, 27, 11, 45, 0)),
        },    
        {
          showtime: "13:15 - 13:45",
          name: "Do. 13:15 - 13:45",
          time: new Date(Date.UTC(2025, 2, 27, 12, 15, 0)),
        },    
        {
          showtime: "13:45 - 18:30",
          name: "Do. 13:45 - 18:30",
          time: new Date(Date.UTC(2025, 2, 27, 12, 45, 0)),
        },
        {
          showtime: "14:00 - 14:45",
          name: "Do. 14:00 - 14:45",
          time: new Date(Date.UTC(2025, 2, 27, 13, 0, 0)),
        },      
        {
          showtime: "14:00 - 15:00",
          name: "Do. 14:00 - 15:00",
          time: new Date(Date.UTC(2025, 2, 27, 13, 0, 0)),
        },    
        {
          showtime: "15:00 - 15:30",
          name: "Do. 15:00 - 15:30",
          time: new Date(Date.UTC(2025, 2, 27, 14, 0, 0)),
        },
        {
          showtime: "15:30 - 16:00",
          name: "Do. 15:30 - 16:00",
          time: new Date(Date.UTC(2025, 2, 27, 14, 30, 0)),
        },
        {
          showtime: "16:00 - 16:45",
          name: "Do. 16:00 - 16:45",
          time: new Date(Date.UTC(2025, 2, 27, 15, 0, 0)),
        },      
        {
          showtime: "16:00 - 17:00",
          name: "Do. 16:00 - 17:00",
          time: new Date(Date.UTC(2025, 2, 27, 15, 0, 0)),
        },
        {
          showtime: "17:00 - 17:30",
          name: "Do. 17:00 - 17:30",
          time: new Date(Date.UTC(2025, 2, 27, 16, 0, 0)),
        },      
        {
          showtime: "17:00 - 18:00",
          name: "Do. 17:00 - 18:00",
          time: new Date(Date.UTC(2025, 2, 27, 16, 0, 0)),
        },
        {
          showtime: "17:30 - 18:00",
          name: "Do. 17:30 - 18:00",
          time: new Date(Date.UTC(2025, 2, 27, 16, 30, 0)),
        },     
        {
          showtime: "18:30 - 19:15",
          name: "Do. 18:30 - 19:15",
          time: new Date(Date.UTC(2025, 2, 27, 17, 30, 0)),
        },    
        {
          showtime: "20:00 - 22:00",
          name: "Do. 20:00 - 22:00",
          time: new Date(Date.UTC(2025, 2, 27, 19, 0, 0)),
        },    
        {
          showtime: "22:00 - 01:00",
          name: "Do. 22:00 - 01:00",
          time: new Date(Date.UTC(2025, 2, 27, 21, 0, 0)),
        },
        // Freitag
        {
          showtime: "07:30 - 11:00",
          name: "Fr. 07:30 - 11:00",
          time: new Date(Date.UTC(2025, 2, 28, 6, 30, 0)),
        },
        {
          showtime: "09:00 - 14:00",
          name: "Fr. 09:00 - 14:00",
          time: new Date(Date.UTC(2025, 2, 28, 8, 0, 0)),
        },
        {
          showtime: "10:00 - 11:00",
          name: "Fr. 10:00 - 11:00",
          time: new Date(Date.UTC(2025, 2, 28, 9, 0, 0)),
        },
      ],
    };
  },
  methods: {
    isInTime(timeName, user) {
      let thistime = Object.values(this.meetings).filter(
        (meeting) =>
          meeting.timeString === timeName &&
          meeting.members.includes(user.email)
      );

      if (thistime.length > 0) {
        return thistime;
      } else {
        return [];
      }
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.selected,
.selected:hover {
  background-color: #4089c9;
  color: white;
}

.selectedRed {
  background-color: #cb1515;
  color: white;
}

.ownScroll {
  height: calc(100vh - 20px);
  overflow-x: scroll;
}
</style>
