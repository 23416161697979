<template>
  <div class="d-flex justify-content-center align-items-center" style="width: 100%; height: 100%; position: absolute;">
    
        <div>
          Anmeldung bitte über das <a href="https://portal.comline.app/" target="_blank">ComLine Portal</a>.
        </div>
  </div>
</template>

<script>
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { mapGetters } from "vuex";

const user = getAuth().currentUser

export default {
  name: 'Login',
  components:{
  },
  computed: {
  ...mapGetters({
    user: "user"
  })
  },
  data() {
    return {
      signedIn: false
    };
  },
  methods: {
  },
  mounted(){
    if (user){
    const claims = user.getIdTokenResult()
    claims.then((result) => {
      if (result.claims.admin === "true"){
        this.signedIn = true
        this.$router.push("/home")
      }
    })
  }

  const token = this.$route.params.token
  
  if (token) {
    signInWithCustomToken(getAuth(), token)
    .then((result)=>{
      const claims = result.user.getIdTokenResult()
      claims.then((payload) => {
        if (payload.claims.admin === "true"){
          this.signedIn = true
          this.$router.push("/home")
        }
      })
    })
    .catch(err=>{console.error(err)})
  }
  }

};
</script>
<style scoped>

</style>