import { render, staticRenderFns } from "./SendMessages.vue?vue&type=template&id=b21f0702&scoped=true"
import script from "./SendMessages.vue?vue&type=script&lang=js"
export * from "./SendMessages.vue?vue&type=script&lang=js"
import style0 from "./SendMessages.vue?vue&type=style&index=0&id=b21f0702&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b21f0702",
  null
  
)

export default component.exports