<template>
    <div class="wrapper">
      <b-container>
        <h4>Verifizierungs-Link erstellen</h4>
        <b-form @submit.prevent="handleSubmit()">
          <b-form-group label="Mail-Adresse" label-for="mail-address">
            <b-input
              id="mail-address"
              v-model="form.mail"
              placeholder="Mail-Adresse hier eingeben"
              required
            />
          </b-form-group>
          <b-button
            type="submit"
            variant="primary"
            :disabled="!form.mail"
            >Link generieren</b-button
          >
        </b-form>
      </b-container>
      <b-container v-if="link">
        <p>Generierter Link:</p>
        <p><i>{{ link }}</i></p>
        <b-button variant="primary" @click="copyText(link)"><span v-if="!linkCopied">Kopieren</span><span v-else>Kopiert &check;</span></b-button>
      </b-container>
    </div>
</template>

<script>
import { functions } from "../firebase";
import { httpsCallable } from "firebase/functions";

export default {
  name: "SendMessages",
  components: {},
  data() {
    return {
      form: {
        mail: "",
      },
      link: "",
      linkCopied: false
    };
  },
  methods: {
    handleSubmit() {
        const generateLink = httpsCallable(functions, "getVerificationLinkByUser");
        generateLink(this.form.mail).then((response) => {
          console.log(response.data)
          if (response.data !== ""){
            this.link = response.data
          }
        }).catch((error) => {
          console.log(error)
        })
        this.form.mail = ""
    },
    copyText(link){
      this.linkCopied = true;
      setTimeout(() => {
        this.linkCopied = false
      }, 3000)
      return navigator.clipboard.writeText(link)
    }
  },
};
</script>

<style scoped>
.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
}
</style>