<template>
  <div class="event">
    <div class="header-image">
      <b-img class="w-100" v-show="event.image" :src="event.image" alt="Face2Face"></b-img>
      <div class="event-headline w-100">
        <h3>{{event.name}}</h3>
      </div>
    </div>
    <div class="content-area">
      <div class="text-center">
        <small>
          <span v-if="event.from">{{checkDate(event.from)}} - </span>
          <span>{{checkDate(event.to)}}</span>
        </small>
      </div>
      <div class="mt-4">
        <p class="description-text">{{event.longText}}</p>
      </div>
    </div>
    <div class="mt-4" v-if="event.agenda" >
      <Agenda :data="event.agenda"/>
    </div>
  </div>
</template>

<script>
import Agenda from './Agenda'

export default {
  name: 'Event',
  props:{
    event: Object
  },
  components:{
    Agenda,
  },
  data() {
    return {
      dateOptions: { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' },
    }
  },
  methods:{
    checkDate(date){
      if (date instanceof Date && date.getTime()){
        return (date.toLocaleDateString('de-DE', this.dateOptions))
      } else {
        return (date.toDate().toLocaleDateString('de-DE', this.dateOptions))
      }
    }
  }
}
</script>

<style>
.header-image { 
  position: relative;
}
.header-image .event-headline {
  position: absolute;
  bottom: 0px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.851), rgb(255, 255, 255));
  height: 50%; 
  display: flex;
  justify-content: center;
}
.header-image .event-headline h3 {
  position: absolute;
  bottom: 0;
}
.item-head.primary {
  background-color: rgba(64, 137, 201, 1) !important;
  color: rgba(64, 137, 201, 1) !important;
  border-color: rgba(64, 137, 201, 1) !important;
}
h5 {
  font-size: 17px;
}
</style>
<style scoped>
  button, button:hover, button:active{
    background-color: rgba(64, 137, 201, 1);
    border-color: rgba(64, 137, 201, 1);
  }
  .content-area {
    margin: 15px; 
  }
</style>