<template>
  <div>
    <nav class="navbar navbar-expand-lg navbar-light">
      <div class="container">
      <router-link class="navbar-brand d-flex" to="/home">
        <Logo :fill="'#ffffff'" :height="'33px'" :width="'33px'" class="mb-0 mr-2 d-flex align-items-center"></Logo>
      </router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto ml-3">
            <template v-if="this.user.loggedIn === true">
              <!-- <b-nav-item>
                <router-link to="/home">Startseite</router-link>
              </b-nav-item> -->
              <!-- <b-nav-item>
                <router-link to="/events">Event erstellen</router-link>
              </b-nav-item> -->
              <b-nav-item>
                <router-link to="/users">Teilnehmer</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/meetings">Termine</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/meetingcheck">Termine prüfen</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/send-messages">Push-Nachrichten</router-link>
              </b-nav-item>
              <b-nav-item>
                <router-link to="/mail-verify">E-Mail Verification</router-link>
              </b-nav-item>
            </template>
          </ul>
        </div>  
        <div>
          <template v-if="this.user.loggedIn === true">
            <a class="nav-link" @click.prevent="signOutNow"><font-awesome-icon :icon="['fas', 'sign-out']" /></a>
          </template>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import { auth } from "../firebase";
import {signOut } from  "firebase/auth";
import { mapGetters } from "vuex"
import Logo from '../assets/Logo.vue'

export default {
  name: 'Navbar',

  components: {
    Logo
  },
  computed: {
    ...mapGetters({
      user: "user"
    })
  },
  methods: {
    signOutNow() {
      signOut(auth)
      .then(async () => {
        await this.$store.dispatch("fetchUser", {loggedIn: false})
        this.$router.replace( {path:"/login"});
      });
    }
  }
};
</script>
<style>
.navbar { 
  /* background-color:#4089C9 !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  margin-bottom: -17px;
}
.navbar a.nav-link,
.navbar li a {
  cursor: pointer;
  color:rgb(255, 255, 255) !important;
}
.navbar a.nav-link:hover,
.navbar li a:hover {
  color:rgb(225, 225, 225)!important;
}
.navbar ul li ul li a {
  color: #3d3d3d !important;
}
.navbar ul li ul li a:hover {
    color: #676767 !important;
}
.navbar ul li ul li a:active {
    color: #fefefe !important;
}
</style>