import Vue from 'vue';
import Home from '../components/Home'
import Events from '../components/Events'
import EventsEdit from '../components/EventsEdit'
import Login from '../components/Login'
import EmailVerify from '../components/EmailVerify'
import Users from '../components/Users'
import Meetings from '../components/Meetings'
import MeetingCheck from '../components/MeetingCheck'
import SendMessages from '../components/SendMessages.vue'
import Router from 'vue-router'
import store from '../store'
import GenerateVerifyLink from '../components/GenerateVerifyLink.vue';

// import { auth } from "../firebase"

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        // { 
        //     path: '*',
        //     redirect: '/login' 
        // },
        { 
            path: '/', 
            redirect: '/login',
        },
        {
            path: '/login/:token',
            name: 'Login',
            component: Login,
            meta: {
                requiresAuth: false,
                requiresAdmin: false,
            }
        },
        {
            path: '/home',
            name: 'Home',
            component: Home,
            meta: {
                requiresAuth: false,
                requiresAdmin: false,
            }
        },
        {
            path: '/events',
            name: 'Events',
            component: Events,
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            }
        },
        {
            path: '/users',
            name: 'Users',
            component: Users,
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            }
        },
        {
            path: '/meetings',
            name: 'Meetings',
            component: Meetings,
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            }
        },
        {
            path: '/events-edit/:id',
            name: 'EventsEdit',
            component: EventsEdit,
            props: true,
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            }
        },
        {
            path: '/email-verifycation/:oob',
            name: 'EMailVerify',
            component: EmailVerify,
            props: true,
            meta: {
                requiresAuth: false,
                requiresAdmin: false,
            }
        },
        {
            path: '/meetingcheck',
            name: 'Meetingcheck',
            component: MeetingCheck,
            props: true,
            meta: {
                requiresAuth: true,
                requiresAdmin: true,
            }
        },
        {
            path: '/send-messages',
            name: 'SendMessages',
            component: SendMessages,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        },
        {
            path: '/mail-verify',
            name: 'MailVerify',
            component: GenerateVerifyLink,
            meta: {
                requiresAuth: true,
                requiresAdmin: true
            }
        }
    ]
});

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const requiresAdmin = to.matched.some(record => record.meta.requiresAdmin)
    const currentUser = store.getters.user.loggedIn
    const isAdmin = store.getters.user.loggedIn ? store.getters.user.data.admin: false

    if (requiresAuth && !currentUser || requiresAdmin && !isAdmin) next({name: 'Login'})
    else if (to.name === 'Login' && currentUser && isAdmin) next({name: 'Users'})
    else if (requiresAuth && currentUser && requiresAdmin && isAdmin) next()
    else next()

})

export default router