var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"events"}},[_c('b-container',{staticClass:"mt-3 mb-3"},[_c('b-row',[_c('b-col',[_c('div',{staticClass:"sticky-top"},[_c('h4',[_vm._v("Teilnehmer")]),_c('b-list-group',_vm._l((_vm.users),function(user,index){return _c('b-list-group-item',{key:index,staticClass:"clickable",class:_vm.selectedUser === index && 'selected',on:{"click":function($event){_vm.selectedUser = index}}},[_c('div',[_vm._v(" "+_vm._s(user.name)),_c('br'),_vm._v(_vm._s(user.company)),_c('br'),_vm._v(_vm._s(user.email)+" ")])])}),1)],1)]),_c('b-col',{staticClass:"sticky-top ownScroll"},[_c('div',[_c('h4',[_vm._v("Termine")]),(_vm.meetings)?_c('b-list-group',_vm._l((_vm.times),function(time,index){return _c('b-list-group-item',{key:time.name + index,staticClass:"clickable",class:(_vm.isInTime(time.name, _vm.users[_vm.selectedUser]).length == 1 &&
                  'selected') ||
                (_vm.isInTime(time.name, _vm.users[_vm.selectedUser]).length > 1 &&
                  'selectedRed')},[_vm._v(_vm._s(time.name)),_c('br'),_vm._l((_vm.isInTime(
                  time.name,
                  _vm.users[_vm.selectedUser]
                )),function(room,index){return _c('span',{key:index},[_vm._v(_vm._s(room.room)+" - "+_vm._s(room.titel)),_c('br')])})],2)}),1):_vm._e()],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }