<template>
  <div id="events">
    <b-col>
      <b-alert show variant="warning text-center">
        <b-form-checkbox
          switch
          v-model="checkDoubled"
          size="xl"
          @click="checkDoubled = !checkDoubled"
          >Prüfung doppelter Terminvergabe</b-form-checkbox
        >
        <p>Achtung! Könnte die Prozesse verlangsamen!</p>
      </b-alert>
      <div v-if="rooms" class="m-5">
        <b-row>
          <b-col cols="2">
            <div class="sticky-top">
              <h4>Räume</h4>
              <b-list-group>
                <b-list-group-item
                  class="clickable"
                  v-for="(room, index) in rooms"
                  :key="index"
                  @click="selectedRoomIndex = index"
                  :class="selectedRoomIndex === index && 'selected'"
                  >{{ room }}</b-list-group-item
                >
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="2 sticky-top ownScroll" style="overflow-x: auto;">
            <div>
              <h4>Zeiten</h4>
              <b-list-group>
                <b-list-group-item
                  class="clickable"
                  v-for="(time, index) in times"
                  :key="time.name + index"
                  @click="selectedTimeIndex = index"
                  :class="selectedTimeIndex === index && 'selected'"
                >
                  <span
                    style="
                      width: 20px !important;
                      position: absolute;
                      right: 0px;
                    "
                    ><font-awesome-icon
                      :icon="['fas', 'circle']"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${index}`]?.members
                          ?.length > 0
                      "
                    />
                  </span>
                  {{ time.name }}</b-list-group-item
                >
              </b-list-group>
            </div>
          </b-col>
          <b-col cols="5">
            <div class="sticky-top">
              <h4>TERMIN</h4>
              <b-alert
                :show="success"
                variant="success"
                @dismiss-count-down="countDownChanged"
              >
                {{ successText }}
              </b-alert>
              <b-list-group>
                <b-list-group-item>
                  <b>{{ rooms[selectedRoomIndex] }}</b>
                  <br />
                  <b>{{ times[selectedTimeIndex].name }} Uhr</b>
                  <br />
                  <b-input-group>
                    <b-form-input
                      placeholder="Bitte Titel hinzufügen..."
                      v-model="meetingTitle"
                      class="pb-3 pt-3"
                    ></b-form-input>
                    <b-input-group-append
                    v-if="meetingTitle"
                      v-show="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.titel !== meetingTitle
                      "
                    >
                      <b-button variant="outline-success" @click="saveTitle()"
                        >Speichern</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <b-input-group class="mt-1">
                    <b-form-input
                      placeholder="Bitte Informationstext hinzufügen..."
                      v-model="meetingSubTitle"
                      class="pb-3 pt-3"
                    ></b-form-input>
                    <b-input-group-append
                    v-if="meetingSubTitle"
                      v-show="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.subTitle !== meetingSubTitle
                      "
                    >
                      <b-button variant="outline-success" @click="saveTitle()"
                        >Speichern</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                  <br />
                  <b-form-checkbox
                    switch
                    v-model="meetingShowMember"
                    size="xl"
                    @change="saveTitle()"
                    >Teilnehmer
                    <span v-show="!meetingShowMember">nicht</span>
                    anzeigen</b-form-checkbox
                  >
                  <hr />
                  <p>Teilnehmer</p>
                  <div class="d-flex justify-content-between mb-3">
                    <b-button
                      size="sm"
                      variant="primary"
                      style="height: 22px; padding-top: 0"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.members.length < users.length ||
                        !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                      "
                      @click="setUser('Hersteller')"
                      >Hersteller</b-button
                    >
                    <b-button
                      size="sm"
                      variant="primary"
                      style="height: 22px; padding-top: 0"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.members.length < users.length ||
                        !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                      "
                      @click="setUser('Mittwoch')"
                      >Mittwoch</b-button
                    >
                    <b-button
                      size="sm"
                      variant="primary"
                      style="height: 22px; padding-top: 0"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.members.length < users.length ||
                        !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                      "
                      @click="setUser('Donnerstag')"
                      >Donnerstag</b-button
                    >
                    <b-button
                      size="sm"
                      variant="primary"
                      style="height: 22px; padding-top: 0"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.members.length < users.length ||
                        !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                      "
                      @click="setUser('ComLine')"
                      >ComLine</b-button
                    >
                    <b-button
                      size="sm"
                      variant="primary"
                      style="height: 22px; padding-top: 0"
                      v-if="
                        meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                          ?.members.length < users.length ||
                        !meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                      "
                      @click="setAll()"
                      >Alle Teilnehmer</b-button
                    >
                    <b-button
                      size="sm"
                      variant="danger"
                      style="height: 22px; padding-top: 0"
                      v-else
                      @click="deleteAll()"
                      >Alle Teilnehmer entfernen</b-button
                    >
                  </div>
                  <div
                    class="d-flex align-items-center justify-content-center h-100 dropArea"
                    @drop="onDrop($event)"
                    @dragover.prevent
                    @dragenter.prevent
                  >
                    <div class="plus">
                      <font-awesome-icon :icon="['fas', 'plus']" />
                    </div>
                  </div>
                  <b-list-group
                    v-if="
                      meetings?.[`${selectedRoomIndex}-${selectedTimeIndex}`]
                        ?.members
                    "
                  >
                    <!-- <b-list-group-item
                      v-for="(member, index) in meetings[
                        `${selectedRoomIndex}-${selectedTimeIndex}`
                      ].members"
                      :key="member + index"
                      :class="
                        isDoubled(times[selectedTimeIndex].time, member) &&
                        'doubled'
                      "
                    > -->
                    <b-list-group-item
                      v-for="(member, index) in sortedMembersOfMeeting"
                      :key="member + index"
                      :class="
                        isDoubled(times[selectedTimeIndex].time, member) &&
                        'doubled'
                      "
                    >
                      <div class="d-flex justify-content-between">
                        <span>
                          <span class="triangle"
                            ><b-badge variant="dark"
                              ><font-awesome-icon
                                :icon="['fas', 'exclamation-triangle']"
                                style="color: white"
                                @click="showDeleteModal(member)"
                              />
                              PERSON IN MEHREREN TERMINEN</b-badge
                            ><br
                          /></span>
                          {{ member?.name
                          }}<br />
                          {{ member?.company
                          }}<br />
                          {{ member?.email }}
                          <b-badge
                            variant="dark"
                            class="m-3"
                            style="position: absolute; right: 0px; bottom: 0px"
                            >{{
                              member?.group
                            }}</b-badge
                          >
                        </span>
                        <font-awesome-icon
                          :icon="['fas', 'trash']"
                          @click="showDeleteModal(member.email)"
                        />
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col>
            <div>
              <h4>Teilnehmer</h4>
              <b-input-group>
                <b-form-input
                  placeholder="Suchen.."
                  v-model="searchInput"
                  class="mb-3"
                ></b-form-input>
              </b-input-group>
              <b-input-group>
                <b-form-select
                  v-model="selectedFilter"
                  :options="options"
                  class="mb-3"
                ></b-form-select>
              </b-input-group>
              <b-list-group>
                <b-list-group-item
                  v-for="element in users"
                  :key="element.id"
                  draggable
                  @dragstart="startDrag($event, element, 'users')"
                  v-show="searchFilter(element)"
                >
                  <div
                    class="dragArea d-flex justify-content-between align-items-center bg-white"
                  >
                    <div>
                      {{ element.name }}<br />{{ element.company }}<br />{{
                        element.email
                      }}
                    </div>
                    <b-badge
                      variant="dark"
                      class="m-3"
                      style="position: absolute; right: 0px; bottom: 0px"
                      >{{ element.group }}</b-badge
                    >
                  </div>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-modal
      id="modal"
      ref="modal"
      title="Teilnehmer Löschen"
      @ok="deleteThis()"
      ok-title="Löschen"
      cancel-title="Abbrechen"
      cancel-variant="danger"
    >
      <p class="my-4">
        Dieser Teilnehmer wird bei bestätigung aus diesem Meeting gelöscht.
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { firestore } from "../firebase";
import { setDoc, doc, updateDoc } from "firebase/firestore";
import store from "../store";

export default {
  name: "Users",
  computed: {
    ...mapGetters({
      users: "users",
      meetings: "meetings",
    }),
    sortedMembersOfMeeting(){
      const order = ["Hersteller", "Mittwoch", "Donnerstag", "ComLine", null, undefined]
      const mailAddresses = []
      const usersOfMeeting = []
      Object.values(this.$store.getters.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`].members).forEach((element) => {mailAddresses.push(element)})
      Object.entries(this.$store.getters.users).forEach((user) => {
        if (mailAddresses.includes(user[1].email)){
          usersOfMeeting.push(user[1])
        }
      })
      return usersOfMeeting.sort(function (a, b) {return order.indexOf(a.group) - order.indexOf(b.group)})
    }
  },
  watch: {
    selectedRoomIndex() {
      this.meetingTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.titel
        ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
            ?.titel
        : "";
      this.meetingSubTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.subTitle
        ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
            ?.subTitle
        : "";
      this.meetingShowMember =
        this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
          ?.meetingShowMember != undefined
          ? this.meetings?.[
              `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
            ]?.meetingShowMember
          : true;
    },
    selectedTimeIndex() {
      this.meetingTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.titel
        ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
            ?.titel
        : "";
      this.meetingSubTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.subTitle
        ? this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
            ?.subTitle
        : "";
      this.meetingShowMember =
        this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
          ?.meetingShowMember != undefined
          ? this.meetings?.[
              `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
            ]?.meetingShowMember
          : true;
    },
  },
  data() {
    return {
      selectedFilter: null,
      options: [
        { value: null, text: "Kein Filter" },
        { value: "Hersteller", text: "Hersteller" },
        { value: "Mittwoch", text: "Mittwoch" },
        { value: "Donnerstag", text: "Donnerstag" },
        { value: "ComLine", text: "ComLine" }
      ],
      checkDoubled: false,
      meetingTitle: "",
      meetingSubTitle: "",
      meetingShowMember: true,
      membertoDelete: null,
      searchInput: "",
      selectedRoomIndex: 0,
      selectedTimeIndex: 0,
      dropData: null,
      success: 0,
      successText: "OK",
      error: false,
      times: [
        // Mittwoch:
        {
          showtime: "10:00 - 12:00",
          name: "Mi. 10:00 - 12:00",
          time: new Date(Date.UTC(2025, 2, 26, 9, 0, 0)),
        },
        {
          showtime: "10:30 - 11:30",
          name: "Mi. 10:30 - 11:30",
          time: new Date(Date.UTC(2025, 2, 26, 9, 30, 0)),
        },
        {
          showtime: "12:00 - 12:30",
          name: "Mi. 12:00 - 12:30",
          time: new Date(Date.UTC(2025, 2, 26, 11, 0, 0)),
        },
        {
          showtime: "12:30 - 15:00",
          name: "Mi. 12:30 - 15:00",
          time: new Date(Date.UTC(2025, 2, 26, 11, 30, 0)),
        },
        {
          showtime: "14:00 - 14:30",
          name: "Mi. 14:00 - 14:30",
          time: new Date(Date.UTC(2025, 2, 26, 13, 0, 0)),
        },
        {
          showtime: "14:00 - 16:00",
          name: "Mi. 14:00 - 16:00",
          time: new Date(Date.UTC(2025, 2, 26, 13, 0, 0)),
        },
        {
          showtime: "15:00 - 16:00",
          name: "Mi. 15:00 - 16:00",
          time: new Date(Date.UTC(2025, 2, 26, 14, 0, 0)),
        },
        {
          showtime: "16:00 - 16:30",
          name: "Mi. 16:00 - 16:30",
          time: new Date(Date.UTC(2025, 2, 26, 15, 0, 0)),
        },
        {
          showtime: "16:30 - 17:00",
          name: "Mi. 16:30 - 17:00",
          time: new Date(Date.UTC(2025, 2, 26, 15, 30, 0)),
        },
        {
          showtime: "17:00 - 17:30",
          name: "Mi. 17:00 - 17:30",
          time: new Date(Date.UTC(2025, 2, 26, 16, 0, 0)),
        },
        {
          showtime: "17:30 - 18:00",
          name: "Mi. 17:30 - 18:00",
          time: new Date(Date.UTC(2025, 2, 26, 16, 30, 0)),
        },
        {
          showtime: "19:00 - 21:00",
          name: "Mi. 19:00 - 21:00",
          time: new Date(Date.UTC(2025, 2, 26, 18, 0, 0)),
        },
        {
          showtime: "21:00 - 23:00",
          name: "Mi. 21:00 - 23:00",
          time: new Date(Date.UTC(2025, 2, 26, 20, 0, 0)),
        },
        // Donnerstag:
        {
          showtime: "07:00 - 09:30",
          name: "Do. 07:00 - 09:30",
          time: new Date(Date.UTC(2025, 2, 27, 6, 0, 0)),
        },
        {
          showtime: "08:00 - 10:00",
          name: "Do. 08:00 - 10:00",
          time: new Date(Date.UTC(2025, 2, 27, 7, 0, 0)),
        },
        {
          showtime: "09:00 - 12:00",
          name: "Do. 09:00 - 12:00",
          time: new Date(Date.UTC(2025, 2, 27, 8, 0, 0)),
        },
        {
          showtime: "09:30 - 10:00",
          name: "Do. 09:30 - 10:00",
          time: new Date(Date.UTC(2025, 2, 27, 8, 30, 0)),
        },
        {
          showtime: "10:00 - 10:30",
          name: "Do. 10:00 - 10:30",
          time: new Date(Date.UTC(2025, 2, 27, 9, 0, 0)),
        },
        {
          showtime: "10:30 - 11:00",
          name: "Do. 10:30 - 11:00",
          time: new Date(Date.UTC(2025, 2, 27, 9, 30, 0)),
        },
        {
          showtime: "11:00 - 11:30",
          name: "Do. 11:00 - 11:30",
          time: new Date(Date.UTC(2025, 2, 27, 10, 0, 0)),
        },
        {
          showtime: "11:30 - 12:30",
          name: "Do. 11:30 - 12:30",
          time: new Date(Date.UTC(2025, 2, 27, 10, 30, 0)),
        },
        {
          showtime: "12:30 - 12:45",
          name: "Do. 12:30 - 12:45",
          time: new Date(Date.UTC(2025, 2, 27, 11, 30, 0)),
        },    
        {
          showtime: "12:45 - 13:15",
          name: "Do. 12:45 - 13:15",
          time: new Date(Date.UTC(2025, 2, 27, 11, 45, 0)),
        },    
        {
          showtime: "13:15 - 13:45",
          name: "Do. 13:15 - 13:45",
          time: new Date(Date.UTC(2025, 2, 27, 12, 15, 0)),
        },    
        {
          showtime: "13:45 - 18:30",
          name: "Do. 13:45 - 18:30",
          time: new Date(Date.UTC(2025, 2, 27, 12, 45, 0)),
        },
        {
          showtime: "14:00 - 14:45",
          name: "Do. 14:00 - 14:45",
          time: new Date(Date.UTC(2025, 2, 27, 13, 0, 0)),
        },      
        {
          showtime: "14:00 - 15:00",
          name: "Do. 14:00 - 15:00",
          time: new Date(Date.UTC(2025, 2, 27, 13, 0, 0)),
        },    
        {
          showtime: "15:00 - 15:30",
          name: "Do. 15:00 - 15:30",
          time: new Date(Date.UTC(2025, 2, 27, 14, 0, 0)),
        },
        {
          showtime: "15:30 - 16:00",
          name: "Do. 15:30 - 16:00",
          time: new Date(Date.UTC(2025, 2, 27, 14, 30, 0)),
        },
        {
          showtime: "16:00 - 16:45",
          name: "Do. 16:00 - 16:45",
          time: new Date(Date.UTC(2025, 2, 27, 15, 0, 0)),
        },      
        {
          showtime: "16:00 - 17:00",
          name: "Do. 16:00 - 17:00",
          time: new Date(Date.UTC(2025, 2, 27, 15, 0, 0)),
        },
        {
          showtime: "17:00 - 17:30",
          name: "Do. 17:00 - 17:30",
          time: new Date(Date.UTC(2025, 2, 27, 16, 0, 0)),
        },      
        {
          showtime: "17:00 - 18:00",
          name: "Do. 17:00 - 18:00",
          time: new Date(Date.UTC(2025, 2, 27, 16, 0, 0)),
        },
        {
          showtime: "17:30 - 18:00",
          name: "Do. 17:30 - 18:00",
          time: new Date(Date.UTC(2025, 2, 27, 16, 30, 0)),
        },     
        {
          showtime: "18:30 - 19:15",
          name: "Do. 18:30 - 19:15",
          time: new Date(Date.UTC(2025, 2, 27, 17, 30, 0)),
        },    
        {
          showtime: "20:00 - 22:00",
          name: "Do. 20:00 - 22:00",
          time: new Date(Date.UTC(2025, 2, 27, 19, 0, 0)),
        },    
        {
          showtime: "22:00 - 01:00",
          name: "Do. 22:00 - 01:00",
          time: new Date(Date.UTC(2025, 2, 27, 21, 0, 0)),
        },
        // Freitag
        {
          showtime: "07:30 - 11:00",
          name: "Fr. 07:30 - 11:00",
          time: new Date(Date.UTC(2025, 2, 28, 6, 30, 0)),
        },
        {
          showtime: "09:00 - 14:00",
          name: "Fr. 09:00 - 14:00",
          time: new Date(Date.UTC(2025, 2, 28, 8, 0, 0)),
        },
        {
          showtime: "10:00 - 11:00",
          name: "Fr. 10:00 - 11:00",
          time: new Date(Date.UTC(2025, 2, 28, 9, 0, 0)),
        },
      ],
      rooms: [
        "Meetingraum 1",
        "Meetingraum 2",
        "Meetingraum 3",
        "Meetingraum 4",
        "Meetingraum 5",
        "Adobe Room",
        "Apple Room",
        "Plenum",
        "Messebereich",
        "Open Space 1",
        "Open Space 2",
        "Open Space 3",
        "Restaurant Wintergarten & Mangold",
        "Mangold & Kaminzimmer & Bar",
        "Hotel Superbude",
        "Hotel Gastwerk"
      ],
    };
  },
  methods: {
    searchFilter(element) {
      if (this.searchInput) {
        return (
          element.name.toUpperCase().includes(this.searchInput.toUpperCase()) ||
          element.company
            .toUpperCase()
            .includes(this.searchInput.toUpperCase()) ||
          element.email.toUpperCase().includes(this.searchInput.toUpperCase())
        );
      }

      if (this.selectedFilter) {
        return (element.group !== undefined && element?.group
          .toUpperCase()
          .includes(this.selectedFilter.toUpperCase()));
      }

      return element;
    },
    selectedtRoomId(id) {
      alert(id);
      return id;
    },
    startDrag(evt, element) {
      this.dropData = element;
    },
    onDrop() {
      let data = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]
        ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
        : { members: [] };
      data.members = Array.isArray(data?.members) ? data.members : [];
      data.members = [...new Set([...data.members, ...[this.dropData.email]])];
      data.titel = this.meetingTitle;
      data.subTitle = this.meetingSubTitle;
      data.room = this.rooms[this.selectedRoomIndex];
      data.time = this.times[this.selectedTimeIndex].time;
      data.timeString = this.times[this.selectedTimeIndex].name;
      data.showtime = this.times[this.selectedTimeIndex].showtime;
      data.meetingShowMember = this.meetingShowMember;

      setDoc(
        doc(
          firestore,
          "meetings",
          `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
        ),
        data
      );
      store.dispatch("fetchMeetings");
    },
    deleteAll() {
      updateDoc(
        doc(
          firestore,
          "meetings",
          `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
        ),
        { members: [] }
      );
      store.dispatch("fetchMeetings");
    },
    countDownChanged(success) {
      this.success = success;
    },
    setAll() {
      const emails = this.users.map(function (obj) {
        return obj.email;
      });
      if (
        this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
      ) {
        updateDoc(
          doc(
            firestore,
            "meetings",
            `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
          ),
          { members: emails }
        ).then(() => {
          this.showSuccess(`Alle Teilnehmer wurden hinzugefügt.`);
        }).then(() => {
          store.dispatch("fetchMeetings");
        });
      } else {
        setDoc(
          doc(
            firestore,
            "meetings",
            `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
          ),
          { members: emails }
        ).then(() => {
          this.showSuccess(`Alle Teilnehmer wurden hinzugefügt.`);
        }).then(() => {
          store.dispatch("fetchMeetings");
        });
      }
    },
    setUser(flag) {
      const emailsFiltered = this.users.filter(function (obj) {
        if (obj.group === flag) return obj.email;
      });

      let emails = emailsFiltered.map(function (obj) {
        return obj.email;
      });

      if (
        Array.isArray(
          this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
            ?.members
        )
      ) {
        emails = [
          ...new Set([
            ...emails,
            ...this.meetings?.[
              `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
            ].members,
          ]),
        ];
      }

      if (
        this.meetings?.[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
      ) {
        updateDoc(
          doc(
            firestore,
            "meetings",
            `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
          ),
          { members: emails }
        ).then(() => {
          this.showSuccess(`Der Teilnehmer wurde hinzugefügt.`);
        }).then(() => {
          store.dispatch("fetchMeetings");
        });
      } else {
        setDoc(
          doc(
            firestore,
            "meetings",
            `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
          ),
          { members: emails }
        ).then(() => {
          this.showSuccess(`Der Teilnehmer wurde hinzugefügt.`);
        }).then(() => {
          store.dispatch("fetchMeetings");
        });
      }
    },
    showSuccess(text) {
      this.success = 1.5;
      this.successText = text;
    },
    showDeleteModal(member) {
      this.membertoDelete = member;
      this.$refs["modal"].show();
    },
    deleteThis() {
      const membertoDelete = this.membertoDelete;
      let data = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]
        ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
        : { members: [] };
      data.members = data.members.filter(function (item) {
        return item !== membertoDelete;
      });
      setDoc(
        doc(
          firestore,
          "meetings",
          `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
        ),
        data
      );
    },
    saveTitle() {
      let data = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]
        ? this.meetings[`${this.selectedRoomIndex}-${this.selectedTimeIndex}`]
        : { members: [] };
      data.titel = this.meetingTitle;
      data.subTitle = this.meetingSubTitle;
      data.room = this.rooms[this.selectedRoomIndex];
      data.time = this.times[this.selectedTimeIndex].time;
      data.showtime = this.times[this.selectedTimeIndex].showtime;
      data.timeString = this.times[this.selectedTimeIndex].name;
      data.meetingShowMember = this.meetingShowMember;

      setDoc(
        doc(
          firestore,
          "meetings",
          `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
        ),
        data
      );
      store.dispatch("fetchMeetings");
    },
    isDoubled(time, email) {
      if (!this.checkDoubled) {
        return false;
      } else {
        //TODO: Wenn möglich schneller machen.
        let meetingsArray = Object.entries(this.meetings);
        meetingsArray = meetingsArray.filter(
          (element) =>
            element[1]?.time &&
            element[1]?.time?.toDate().toLocaleString("de-DE") ===
              time?.toLocaleString("de-DE") &&
            element[1].members.includes(email)
        );
        return meetingsArray.length > 1 ? true : false;
      }
    },
  },
  mounted(){
    this.meetingTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.titel
    this.meetingSubTitle = this.meetings?.[
        `${this.selectedRoomIndex}-${this.selectedTimeIndex}`
      ]?.subTitle
  }
};
</script>

<style scoped>
#admin {
  padding-top: 30px;
}
body,
div {
  -ms-user-select: None;
  -moz-user-select: None;
  -webkit-user-select: None;
  user-select: None;
}
.dragable_logo {
  max-width: 50px;
  max-height: 50px;
}
small {
  font-size: 10px;
  color: lightgray;
}
.bg-white {
  background-color: #ffffff;
}
.dropArea {
  border: 1px #6f6f6f dashed;
  border-radius: 5px;
  width: 100%;
  min-height: 90px;
  margin: 3px;
  margin-left: 0;
  padding: 3px;
}
.plus {
  color: #aeaeae;
}
.dropArea.done {
  border: 1px solid;
  border-color: green;
}
.dropArea.empty {
  border-color: orange;
}
.dragArea {
  cursor: grab;
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  background-color: #ececec;
}
.doubled {
  background-color: rgb(255, 221, 221);
}
span.triangle {
  display: none;
}
.doubled span.triangle {
  display: block;
}
.selected,
.selected:hover {
  background-color: #4089c9;
  color: white;
}
.inUse,
.inUse:hover {
  background-color: #a1a1a1;
  color: rgb(0, 0, 0);
}
.ownScroll {
  height: calc(100vh - 68px);
  overflow-x: scroll;
}
</style>
